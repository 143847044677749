<template>
  <div>
    <div class="container-buttons">
      <b-button
        v-if="allows_crud && !rubric"
        size="sm"
        variant="primary"
        class="mb-2 mt-4 ml-2"
        @click="
          $bvModal.show(
            `modal-create-rubric-${instrument_id}-${container_instrument_id}`
          )
        "
        >+ Agregar
        {{ $getVisibleNames("evaluations2.rubric", false, "Pauta") }}</b-button
      >
    </div>
    <div
      class="d-flex justify-content-left mb-2 mr-2 noprint"
      v-if="rubric && !evaluatee_view && !evaluator_view && !preview_evaluation"
    >
      <b-button
        variant="primary"
        size="sm"
        class="noprint"
        @click="
          $bvModal.show(
            `modal-specification-table-rubric-${test.id}-${container_instrument_id}-${rubric.id}`
          )
        "
      >
        <div class="d-flex align-items-center">
          <b-icon icon="card-list" class="mr-1"></b-icon>
          <span>Tabla de Especificaciones</span>
        </div>
      </b-button>
      <b-modal
        :id="`modal-specification-table-rubric-${test.id}-${container_instrument_id}-${rubric.id}`"
        :title="`Tabla de Especificaciones`"
        hide-footer
        size="xl"
      >
        <NewSpecificationTable
          :allows_crud="allows_crud"
          :test="test"
          :observation_table="true"
          :rubric_id="rubric.id"
          :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
        ></NewSpecificationTable>
      </b-modal>
    </div>
    <HeaderRubric
      v-if="rubric && filter_rubric_instance == null"
      :container_instrument_id="container_instrument_id"
      :instrument_id="instrument_id"
      :rubric_id="rubric.id"
      :allows_crud="allows_crud"
      @reset_rubric_coevaluators="() => key_coevaluator_rubric++"
    ></HeaderRubric>
    <b-table
      v-if="rubric && filter_rubric_instance == null"
      :fields="fields"
      :items="rubricAchievementList"
      small
      caption-top
      bordered
      show-empty
      empty-text="No hay niveles de logro para mostrar."
    >
      <template #cell(description)="row">
        <div class="rich-text-content" v-html="row.item.description"></div>
      </template>
    </b-table>
    <CoEvaluatorRubric
      v-if="
        test &&
        test.evaluatees.length > 1 &&
        rubric &&
        evaluative_agent &&
        (['duoc_coevaluacion_evaluative_agent'].includes(
          evaluative_agent.internal_use_id
        ) ||
          $equals(evaluative_agent.name, 'Coevaluación'))
      "
      :key="key_coevaluator_rubric"
      :rubric="rubric"
      :test="test"
      :user_id="user_id"
      :allows_crud="allows_crud"
      :evaluatee_view="evaluatee_view"
      :is_running_test="is_running_test"
      :finished_test="finished_test"
      :evaluator_view="evaluator_view"
    ></CoEvaluatorRubric>
    <SelectedCoevaluatorRubric
      v-if="
        rubric &&
        ((((is_running_test && !finished_test) ||
          (!is_running_test && finished_test)) &&
          evaluatee_view) ||
          evaluator_view)
      "
      :rubric="rubric"
      :test="test"
      :user_id="user_id"
      :allows_crud="allows_crud"
      :evaluatee_view="evaluatee_view"
      :is_running_test="is_running_test"
      :finished_test="finished_test"
      :evaluator_view="evaluator_view"
      @student_selcted="slotStudentSelected"
    >
    </SelectedCoevaluatorRubric>
    <!-- <div>user_id:{{ user_id }}</div>
    <div>coevaluator_id:{{ coevaluator_id }}</div>
    <div>currentUserId:{{ currentUserId }}</div>
    <div>currentCoevaluatorId:{{ currentCoevaluatorId }}</div> -->
    <!-- Boton para Ocultar columnas -->
    <div
      v-if="evaluator_view && filter_rubric_instance == null"
      class="text-right mb-2"
    >
      <b-button
        variant="primary"
        size="sm"
        :id="`display-columns-rubric-${test.id}`"
        class="noprint"
        >Ocultar Columnas
      </b-button>
    </div>
    <b-popover
      v-if="evaluator_view && filter_rubric_instance == null"
      :target="`display-columns-rubric-${test.id}`"
      triggers="click blur"
    >
      <div>
        <div class="text-left noprint">
          <b-form-checkbox
            :id="`column-check-1`"
            name="checkbox-1"
            v-model="hide_number"
            @change="updateCheckbox('hide_number', hide_number)"
          >
            Número
          </b-form-checkbox>
        </div>
        <div class="text-left noprint">
          <b-form-checkbox
            :id="`column-check-2`"
            name="checkbox-2"
            v-model="hide_dimension"
            @change="updateCheckbox('hide_dimension', hide_dimension)"
          >
            {{ $getVisibleNames("manual.dimension", true, "Dimensiones") }}
          </b-form-checkbox>
        </div>
        <div class="text-left noprint">
          <b-form-checkbox
            :id="`column-check-3`"
            name="checkbox-3"
            v-model="hide_weighing"
            @change="updateCheckbox('hide_weighing', hide_weighing)"
          >
            Ponderación
          </b-form-checkbox>
        </div>
        <div
          v-if="institution && institution.internal_use_id != 'duoc_uc'"
          class="text-left noprint"
        >
          <b-form-checkbox
            id="column-check-4"
            name="checkbox-4"
            v-model="hide_evaluation_criteria"
            @change="
              updateCheckbox(
                'hide_evaluation_criteria',
                hide_evaluation_criteria
              )
            "
          >
            {{
              $getVisibleNames(
                "teaching.evaluationcriteriamicro",
                false,
                "Criterio de Evaluación Micro"
              )
            }}
          </b-form-checkbox>
        </div>
      </div>
    </b-popover>
    <div
      :class="{
        'rubric-achievement-overflow': rubricAchievementList.length > 5,
      }"
    >
      <table v-if="rubric">
        <thead>
          <!-- Titulo Niveles de logro -->
          <tr>
            <!-- :colspan="
                evaluation &&
                (evaluation.matter_evaluation != null ||
                  evaluation.egress_profile_matter_evaluation != null ||
                  (evaluation.parent_evaluation != null &&
                    evaluation_criteria_micro_ids.length > 0)) &&
                institution &&
                institution.internal_use_id != 'duoc_uc'
                  ? 3
                  : 2
              " -->
            <th
              :class="{
                'd-none':
                  (institution &&
                    institution.internal_use_id == 'duoc_uc' &&
                    hide_dimension == true &&
                    hide_number == true &&
                    evaluator_view) ||
                  (institution &&
                    institution.internal_use_id != 'duoc_uc' &&
                    hide_dimension == true &&
                    hide_number == true &&
                    hide_evaluation_criteria == true &&
                    evaluator_view),
              }"
              :colspan="colspanRubric"
            ></th>
            <th
              :colspan="
                rubricAchievementList.length > 0
                  ? rubricAchievementList.length
                  : 1
              "
            >
              {{
                $getVisibleNames(
                  "evaluations2.rubricperformancelevel",
                  true,
                  "Niveles de Logro"
                ).toUpperCase()
              }}
              <button-add
                v-if="allows_crud && !rubric.is_locked"
                class="add-button"
                :title="`Crear ${$getVisibleNames(
                  'evaluations2.rubricperformancelevel',
                  false,
                  'Niveles de Logro'
                ).toUpperCase()}`"
                v-b-tooltip.v-secondary.top.noninteractive="
                  `Crear ${$getVisibleNames(
                    'evaluations2.rubricperformancelevel',
                    false,
                    'Niveles de Logro'
                  )}`
                "
                @click="
                  $bvModal.show(
                    `modal-create-rubric-achievement-${instrument_id}-${container_instrument_id}`
                  )
                "
                size="sm"
              >
              </button-add>
            </th>
            <th
              :colspan="!rubric.is_locked ? 2 : 1"
              :class="{ 'd-none': hide_weighing == true && evaluator_view }"
            ></th>
          </tr>
          <tr>
            <!-- N° -->
            <th
              style="width: 1%"
              :class="{ 'd-none': hide_number == true && evaluator_view }"
            >
              N°
            </th>
            <!-- Dimensiones -->
            <th
              style="width: 15%"
              :class="{ 'd-none': hide_dimension == true && evaluator_view }"
            >
              {{
                $getVisibleNames(
                  "manual.dimension",
                  true,
                  "Dimensión"
                ).toUpperCase()
              }}
              <button-add
                v-if="allows_crud && !rubric.is_locked"
                class="add-button"
                :title="`Crear ${$getVisibleNames(
                  'manual.dimension',
                  false,
                  'Dimensión'
                )}`"
                v-b-tooltip.v-secondary.top.noninteractive="
                  `Crear ${$getVisibleNames(
                    'manual.dimension',
                    false,
                    'Dimensión'
                  )}`
                "
                @click="openModalDimensionCreate"
                size="sm"
              >
              </button-add>
            </th>
            <th
              v-if="
                evaluation &&
                (evaluation.matter_evaluation != null ||
                  evaluation.egress_profile_matter_evaluation != null ||
                  (evaluation.parent_evaluation != null &&
                    evaluation_criteria_micro_ids.length > 0)) &&
                institution &&
                institution.internal_use_id != 'duoc_uc'
              "
              style="width: 15%"
              :class="{
                'd-none': hide_evaluation_criteria == true && evaluator_view,
              }"
            >
              {{
                $getVisibleNames(
                  "teaching.evaluationcriteriamicro",
                  false,
                  "Criterio de Evaluación Micro"
                ).toUpperCase()
              }}
            </th>
            <!-- Niveles de Logro Insertados -->
            <th
              v-for="rubric_achievement in rubricAchievementList"
              :key="rubric_achievement.id"
              style="width: auto"
            >
              {{ rubric_achievement.title.toUpperCase() }}
              <br />
              {{ rubric_achievement.score }}
              <template v-if="rubric.show_percentage_sign">%</template>
              <template v-else> PUNTOS</template>
              <button-edit
                v-if="allows_crud && !rubric.is_locked"
                class="add-button"
                @click="
                  $bvModal.show(
                    `modal-update-rubric-achievement-${instrument_id}-${rubric_achievement.id}-${container_instrument_id}`
                  )
                "
                v-b-tooltip.top.noninteractive.v-secondary="
                  `Editar ${$getVisibleNames(
                    'evaluations2.rubricperformancelevel',
                    false,
                    'Niveles de Logro'
                  )}`
                "
              ></button-edit>
              <b-button
                v-if="allows_crud && !rubric.is_locked"
                v-b-tooltip.top.noninteractive.v-secondary="
                  `Eliminar ${$getVisibleNames(
                    'evaluations2.rubricperformancelevel',
                    false,
                    'Niveles de Logro'
                  )}`
                "
                class="action-button-delete delete-btn"
                variant="none"
                @click="deleteRubricAchievement(rubric_achievement.id)"
              >
                <!-- v-can="'evaluations2.delete_evaluation'" -->
                <b-icon-trash font-scale="1"></b-icon-trash>
              </b-button>

              <b-modal
                :id="`modal-update-rubric-achievement-${instrument_id}-${rubric_achievement.id}-${container_instrument_id}`"
                :title="`Editar ${$getVisibleNames(
                  'evaluations2.rubricperformancelevel',
                  false,
                  'Niveles de Logro'
                )}`"
                size="lg"
                hide-footer
                no-enforce-focus
              >
                <RubricAchievementForm
                  :rubric_id="rubric.id"
                  :RubricAchievement="rubric_achievement"
                  :order="rubric_achievement.order"
                  :show_delete_button="true"
                  @updated="
                    $bvModal.hide(
                      `modal-update-rubric-achievement-${instrument_id}-${rubric_achievement.id}-${container_instrument_id}`
                    )
                  "
                  @deleted="
                    $bvModal.hide(
                      `modal-update-rubric-achievement-${instrument_id}-${rubric_achievement.id}-${container_instrument_id}`
                    )
                  "
                ></RubricAchievementForm>
              </b-modal>
            </th>
            <th v-if="rubricAchievementList.length == 0">
              No tiene
              {{
                $getVisibleNames(
                  "evaluations2.rubricperformancelevel",
                  true,
                  "Niveles de Logro"
                )
              }}
            </th>
            <!-- Ponderación -->
            <th
              style="width: 1%"
              :class="{ 'd-none': hide_weighing == true && evaluator_view }"
              v-b-tooltip.top.noninteractive.v-secondary="
                `${
                  institution && institution.internal_use_id != 'duoc_uc'
                    ? `Corresponde a la Ponderación de la ${$getVisibleNames(
                        'manual.dimension',
                        false,
                        'Dimensión'
                      )}`
                    : ''
                }`
              "
            >
              %
            </th>
            <!-- Columna Editar -->
            <th style="width: 1%" v-if="allows_crud && !rubric.is_locked"></th>
          </tr>
        </thead>
        <draggable
          v-model="rubricInstanceList"
          :disabled="!allows_crud || rubric.is_locked"
          tag="tbody"
          handle=".handle"
        >
          <template v-for="rubric_criteria in rubricInstanceList">
            <tr :key="`${rubric_criteria.id}`">
              <td
                class="handle"
                :class="{ 'd-none': hide_number == true && evaluator_view }"
              >
                <b-icon
                  v-if="allows_crud && !rubric.is_locked"
                  class="handle-icon"
                  icon="arrows-expand"
                  scale="1.2"
                ></b-icon>
                <template v-else>
                  {{ rubric_criteria.order }}
                </template>
              </td>
              <td
                class="td-rubric-descriptor-cell"
                :class="{ 'd-none': hide_dimension == true && evaluator_view }"
              >
                <div class="container-dimension">
                  <div class="pt-1 sentence-cell">
                    <SentenceContainer
                      class="ml-1"
                      :class="{
                        'unselectable-text':
                          (user && user.groups.includes(7)) ||
                          preview_evaluation,
                      }"
                      :unique_id_sentence="
                        rubric_criteria.id +
                        '-' +
                        rubric_criteria.order +
                        rubric_criteria.full_sentence
                      "
                      :Sentence="rubric_criteria"
                      :showColorCognitiveLevel="allows_crud"
                      :showCognitiveLevel="!preview_evaluation"
                      :boundedTextContainer="
                        (evaluator_view ||
                          evaluatee_view ||
                          preview_evaluation) &&
                        rubric &&
                        !rubric.allow_criteria_performance_level_input
                          ? true
                          : false
                      "
                      :single_line="
                        (evaluator_view ||
                          evaluatee_view ||
                          preview_evaluation) &&
                        rubric &&
                        !rubric.allow_criteria_performance_level_input
                          ? true
                          : false
                      "
                    ></SentenceContainer>
                  </div>
                </div>
              </td>
              <td
                v-if="
                  evaluation &&
                  (evaluation.matter_evaluation != null ||
                    evaluation.egress_profile_matter_evaluation != null ||
                    (evaluation.parent_evaluation != null &&
                      evaluation_criteria_micro_ids.length > 0)) &&
                  institution &&
                  institution.internal_use_id != 'duoc_uc'
                "
                class="td-rubric-descriptor-cell"
                :class="{
                  'd-none': hide_evaluation_criteria == true && evaluator_view,
                }"
              >
                <div class="contailer-il-rubric">
                  <div
                    v-for="ec_micros in rubric_criteria.evaluation_criterias_micro"
                    :key="
                      ec_micros +
                      'evaluation_criterias_micro' +
                      rubric_criteria.id
                    "
                  >
                    <div class="pt-1 sentence-cell container-sentence">
                      <SentenceContainer
                        v-if="
                          evaluation_criteria_micros.find(
                            (x) => x.id == ec_micros
                          )
                        "
                        class="ml-1 text-cell-sencente"
                        :class="{
                          'unselectable-text':
                            (user && user.groups.includes(7)) ||
                            preview_evaluation,
                        }"
                        :unique_id_sentence="
                          rubric_criteria.id +
                          '-' +
                          rubric_criteria.order +
                          ec_micros
                        "
                        :Sentence="
                          evaluation_criteria_micros.find(
                            (x) => x.id == ec_micros
                          )
                        "
                        :showColorCognitiveLevel="allows_crud"
                        :showCognitiveLevel="!preview_evaluation"
                        :boundedTextContainer="false"
                      >
                        <template v-slot:order>
                          <span>
                            {{ orderEvaluationCriteriaMicro(ec_micros) }}
                          </span>
                        </template>
                      </SentenceContainer>
                    </div>
                  </div>
                </div>
                <div
                  v-if="rubric_criteria.evaluation_criterias_micro.length == 0"
                  class="d-flex ml-1 mr-auto mt-1"
                >
                  N/A
                </div>
              </td>
              <td
                v-for="rubric_achievement in rubricAchievementList"
                :key="rubric_achievement.id"
                class="td-rubric-descriptor-cell"
                :class="{
                  'vertical-align-cell':
                    rubric && !rubric.allow_criteria_performance_level_input,
                }"
              >
                <RubricDescriptorCell
                  :class="{
                    'unselectable-text':
                      (user && user.groups.includes(7)) || preview_evaluation,
                  }"
                  :key="
                    rubric_criteria.id +
                    '-' +
                    rubric_achievement.id +
                    '-' +
                    key_rubric_descriptor_cell
                  "
                  :container_instrument_id="container_instrument_id"
                  :rubric="rubric"
                  :rubric_criteria_id="rubric_criteria.id"
                  :rubric_achievement_id="rubric_achievement.id"
                  :allows_crud="allows_crud && !rubric.is_locked"
                  :evaluator_view="evaluator_view"
                  :evaluatee_view="evaluatee_view"
                  :is_running_test="is_running_test"
                  :finished_test="finished_test"
                  :is_published="is_published"
                  :preview_evaluation="preview_evaluation"
                  :user_id="currentUserId"
                  :coevaluator_id="currentCoevaluatorId"
                  :closedEvaluationScore="closedEvaluationScore"
                  @created_score="changeEvaluateeTest"
                  @updated_score="changeEvaluateeTest"
                  @resetRubricDescriptorCell="
                    () => key_rubric_descriptor_cell++
                  "
                ></RubricDescriptorCell>
              </td>
              <td
                :class="{ 'd-none': hide_weighing == true && evaluator_view }"
              >
                <div
                  :class="{ 'weighing-red': rubric_criteria.weighing == 0 }"
                  v-b-tooltip.top.danger.v-danger="
                    `${
                      rubric_criteria.weighing == 0
                        ? `Este ${$getVisibleNames(
                            'manual.dimension',
                            false,
                            'Dimensión'
                          )} no pondera`
                        : ''
                    }`
                  "
                >
                  {{ rubric_criteria.weighing }}%
                </div>
              </td>
              <td v-if="rubricAchievementList.length == 0"></td>
              <td v-if="allows_crud && !rubric.is_locked">
                <div class="container-buttons-dimension">
                  <button-edit
                    class="icon-edit"
                    @click="openModalDimensionEdit(rubric_criteria.id)"
                  ></button-edit>

                  <b-button
                    v-if="allows_crud"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Eliminar la ${$getVisibleNames(
                        'manual.dimension',
                        false,
                        'Dimensión'
                      )}`
                    "
                    class="action-button-delete-dimension delete-btn"
                    variant="none"
                    @click="deleteRubricCriteria(rubric_criteria.id)"
                  >
                    <!-- v-can="'evaluations2.delete_evaluation'" -->
                    <b-icon-trash
                      class="icon-trash"
                      font-scale="1"
                    ></b-icon-trash>
                  </b-button>
                </div>
                <b-modal
                  :id="`modal-update-rubric-instance-${instrument_id}-${rubric_criteria.id}-${container_instrument_id}`"
                  :title="
                    'Modificar ' +
                    $getVisibleNames('manual.dimension', false, 'Dimensión')
                  "
                  hide-footer
                  size="xl"
                  no-enforce-focus
                  @hide="hideModalDimension"
                >
                  <RubricCriteriaForm
                    v-if="rubric"
                    :rubric_id="rubric.id"
                    :RubricCriteria="rubric_criteria"
                    :order="rubric_criteria.order"
                    :evaluation_criteria_micro_ids="
                      evaluation_criteria_micro_ids
                    "
                    :show_delete_button="true"
                    @updated="
                      $bvModal.hide(
                        `modal-update-rubric-instance-${instrument_id}-${rubric_criteria.id}-${container_instrument_id}`
                      )
                    "
                    @deleted="
                      $bvModal.hide(
                        `modal-update-rubric-instance-${instrument_id}-${rubric_criteria.id}-${container_instrument_id}`
                      )
                    "
                  ></RubricCriteriaForm>
                </b-modal>
              </td>
            </tr>
          </template>
        </draggable>
        <template v-if="rubricInstanceList.length == 0">
          <tbody>
            <b-tr>
              <b-td
                :colspan="
                  allows_crud
                    ? 5 + rubricAchievementList.length
                    : 4 + rubricAchievementList.length
                "
              >
                <strong>
                  No hay
                  {{
                    $getVisibleNames("manual.dimension", true, "Dimensiones")
                  }}
                  creadas
                </strong>
              </b-td>
            </b-tr>
          </tbody>
        </template>
        <tfoot
          v-if="filter_rubric_instance == null"
          :class="{ 'd-none': hide_weighing == true && evaluator_view }"
        >
          <tr>
            <td
              :class="{
                'd-none':
                  (institution &&
                    institution.internal_use_id == 'duoc_uc' &&
                    hide_dimension == true &&
                    hide_number == true &&
                    evaluator_view) ||
                  (institution &&
                    institution.internal_use_id != 'duoc_uc' &&
                    hide_dimension == true &&
                    hide_number == true &&
                    hide_evaluation_criteria == true &&
                    evaluator_view),
              }"
              :colspan="colspanRubric"
            ></td>
            <td
              v-for="(rubric_achievement, index) in rubricAchievementList"
              :key="rubric_achievement.id"
            >
              <template v-if="rubricAchievementList.length == index + 1">
                <strong>Total</strong>
              </template>
            </td>
            <td v-if="rubricAchievementList.length == 0">
              <strong>Total</strong>
            </td>
            <td
              :title="
                totalWeighing != 100
                  ? 'La ponderación total debe ser igual a 100.'
                  : ''
              "
              v-b-tooltip.v-secondary.noninteractive
              v-bind:style="{ color: totalWeighing != 100 ? 'red' : '' }"
            >
              <strong>{{ totalWeighing }}%</strong>
            </td>
            <td v-if="allows_crud && !rubric.is_locked"></td>
          </tr>
        </tfoot>
      </table>
    </div>
    <b-modal
      :id="`modal-created-rubric-criteria-${instrument_id}-${container_instrument_id}`"
      :title="`Crear ${$getVisibleNames(
        'manual.dimension',
        false,
        'Dimensión'
      )}`"
      size="xl"
      hide-footer
      no-enforce-focus
      @hide="hideModalDimension"
    >
      <RubricCriteriaForm
        v-if="rubric"
        :rubric_id="rubric.id"
        :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
        :order="
          rubricInstanceList.length > 0
            ? rubricInstanceList[rubricInstanceList.length - 1].order + 1
            : 1
        "
        @created="
          $bvModal.hide(
            `modal-created-rubric-criteria-${instrument_id}-${container_instrument_id}`
          )
        "
      ></RubricCriteriaForm>
    </b-modal>
    <b-modal
      :id="`modal-create-rubric-achievement-${instrument_id}-${container_instrument_id}`"
      :title="`Crear el ${$getVisibleNames(
        'evaluations2.rubricperformancelevel',
        false,
        'Niveles de Logro'
      )}`"
      size="lg"
      hide-footer
      no-enforce-focus
    >
      <RubricAchievementForm
        v-if="rubric"
        :rubric_id="rubric.id"
        :order="
          rubricAchievementList.length > 0
            ? rubricAchievementList[rubricAchievementList.length - 1].order + 1
            : 1
        "
        @created="
          $bvModal.hide(
            `modal-create-rubric-achievement-${instrument_id}-${container_instrument_id}`
          )
        "
      ></RubricAchievementForm>
    </b-modal>
    <b-modal
      :id="`modal-create-rubric-${instrument_id}-${container_instrument_id}`"
      :title="`Crear ${$getVisibleNames(
        'evaluations2.rubric',
        false,
        'Pauta'
      )}`"
      size="lg"
      hide-footer
      no-enforce-focus
    >
      <NewRubricForm
        :test_id="instrument_id"
        @closeModal="
          $bvModal.hide(
            `modal-create-rubric-${instrument_id}-${container_instrument_id}`
          )
        "
      ></NewRubricForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { toast } from "@/utils/utils";

export default {
  name: "RubricsContainer",
  components: {
    draggable,
    NewSpecificationTable: () =>
      import(
        "@/components/new-evaluations/Evaluation/SpecificationTable/NewSpecificationTable"
      ),
    RubricCriteriaForm: () =>
      import("@/components/new-evaluations/Rubrics/RubricCriteriaForm"),
    NewRubricForm: () =>
      import("@/components/new-evaluations/Rubrics/NewRubricForm"),
    CoEvaluatorRubric: () =>
      import("@/components/new-evaluations/Rubrics/CoEvaluatorRubric"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    RubricAchievementForm: () =>
      import("@/components/new-evaluations/Rubrics/RubricAchievementForm"),
    RubricDescriptorCell: () =>
      import("@/components/new-evaluations/Rubrics/RubricDescriptorCell"),
    HeaderRubric: () =>
      import("@/components/new-evaluations/Rubrics/HeaderRubric"),
    SelectedCoevaluatorRubric: () =>
      import("@/components/new-evaluations/Rubrics/SelectedCoevaluatorRubric"),
  },
  props: {
    instrument_id: {
      required: true,
      type: Number,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
      required: true,
    },
    container_instrument_id: {
      type: String,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
    is_published: {
      type: Boolean,
      default: false,
    },
    user_id: {
      type: Number,
      required: false,
    },
    // para restringir que el evaluador pueda colocar notas fuera del periodo actual con respecto al de la sección.
    closedEvaluationScore: {
      type: Boolean,
      default: false,
    },
    // Para que solo uno de los componentes pida las peticiones y el otro solo sirva para el imprimir todos los test en la misma línea.
    // y eso se ejecutaria solo si es !evaluatee_view && !evaluator_view
    requests_within_the_component: {
      type: Boolean,
      default: true,
    },
    // para que cuando se llame el componente no haga las peticiones nuevamente.
    no_requests_in_view: {
      type: Boolean,
      default: false,
    },
    // Filtro para mostrar las preguntas en "NewQuestionCard"
    filter_rubric_instance: {
      type: Number,
    },
  },
  data() {
    return {
      key_rubric_descriptor_cell: 0,
      key_coevaluator_rubric: 0,
      coevaluator_id: null,
      hide_number: false,
      hide_dimension: false,
      hide_weighing: false,
      hide_evaluation_criteria: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      evaluations: names.NEW_EVALUATIONS,
      tests: names.NEW_TESTS,
      evaluative_agents: names.NEW_TEST_TYPES,
      competence_units: names.COMPETENCE_UNITS,
      study_units: names.STUDY_UNITS,
      evaluation_criteria_micros: names.EVALUATION_CRITERIAS,
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      rubricAchievements: names.NEW_RUBRIC_ACHIEVEMENTS,
      rubricInstances: names.NEW_RUBRIC_CRITERIAS,
      rubricDescriptors: names.NEW_RUBRIC_DESCRIPTORS,
      evaluatee_rubrics: names.EVALUATEE_RUBRICS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
    }),
    test() {
      return this.tests.find((x) => x.id == this.instrument_id);
    },
    evaluation() {
      if (!this.test) return null;
      return this.evaluations.find((x) => x.id == this.test.evaluation);
    },
    egressProfileMatter() {
      if (!this.evaluation) return [];
      return this.egress_profile_matters.find(
        (x) => x.id == this.evaluation.scope_id
      );
    },
    evaluative_agent() {
      if (!this.test) return null;
      return this.evaluative_agents.find(
        (x) => this.test.evaluative_agent == x.id
      );
    },
    rubric() {
      return this.rubrics.find((x) => x.test == this.instrument_id);
    },
    evaluateeRubric() {
      if (
        this.user_id &&
        this.rubric &&
        (this.evaluatee_view || this.evaluator_view)
      )
        return this.evaluatee_rubrics.find(
          (x) => x.evaluatee == this.user_id && x.rubric == this.rubric.id
        );
      return null;
    },
    selectedUsers() {
      if (!this.evaluateeRubric) return [];
      if (this.evaluatee_view && this.is_running_test && !this.finished_test) {
        return this.evaluateeRubric.coevaluators;
      } else if (
        this.evaluator_view ||
        (this.evaluatee_view && this.finished_test)
      ) {
        return this.evaluateeRubric.coevaluating;
      }
      return [];
    },
    rubricAchievementList() {
      if (!this.rubric) return [];
      return this.rubricAchievements
        .filter((x) => x.rubric == this.rubric.id)
        .sort(function (a, b) {
          return a.score > b.score ? -1 : 1;
        });
    },
    colspanRubric() {
      let column = 0;
      if (
        this.evaluation &&
        (this.evaluation.matter_evaluation != null ||
          this.evaluation.egress_profile_matter_evaluation != null ||
          (this.evaluation.parent_evaluation != null &&
            this.evaluation_criteria_micro_ids.length > 0)) &&
        this.institution &&
        this.institution.internal_use_id == "duoc_uc"
      ) {
        column += 2;
      }
      if (
        this.evaluation &&
        (this.evaluation.matter_evaluation != null ||
          this.evaluation.egress_profile_matter_evaluation != null ||
          (this.evaluation.parent_evaluation != null &&
            this.evaluation_criteria_micro_ids.length > 0)) &&
        this.institution &&
        this.institution.internal_use_id != "duoc_uc"
      ) {
        column += 3;
      }
      if (this.hide_number == true && this.evaluator_view) {
        column -= 1;
      }
      if (this.hide_dimension == true && this.evaluator_view) {
        column -= 1;
      }
      if (this.hide_evaluation_criteria == true && this.evaluator_view) {
        column -= 1;
      }
      return column;
    },
    rubricInstanceList: {
      get() {
        if (this.filter_rubric_instance == null) {
          return this.rubricInstances
            .filter((x) => x.rubric == this.rubric.id)
            .sort(function (a, b) {
              if (a.order < b.order) return -1;
              if (a.order > b.order) return 1;
              return 0;
            });
        } else {
          return this.rubricInstances
            .filter(
              (x) =>
                x.rubric == this.rubric.id &&
                x.id == this.filter_rubric_instance
            )
            .sort(function (a, b) {
              if (a.order < b.order) return -1;
              if (a.order > b.order) return 1;
              return 0;
            });
        }
      },
      set(list) {
        list.forEach((element, index) => {
          if (!isNaN(element.id)) {
            let payload = {
              rubric_instance_id: element.id,
              item: {
                order: index + 1,
              },
            };
            this.$store.dispatch(names.PATCH_NEW_RUBRIC_CRITERIA, payload);
          }
        });
      },
    },
    fields() {
      return [
        {
          key: "title",
          label: "CATEGORÍA",
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white",
          },
          tdClass: "text-left",
        },
        {
          key: "score",
          label:
            this.rubric && this.rubric.show_percentage_sign
              ? "% LOGRO"
              : "PUNTAJE",
          tdClass: "text-center",
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white",
            width: "80px",
          },
          formatter: (value) => {
            if (this.rubric && this.rubric.show_percentage_sign)
              return value + "%";
            else return value;
          },
        },
        {
          key: "description",
          label: "DESCRIPCIÓN NIVELES DE LOGRO",
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white",
          },
          tdClass: "text-left",
        },
      ];
    },
    totalWeighing() {
      let weighing = 0;
      this.rubricInstanceList.forEach((element) => {
        weighing += Number(element.weighing);
      });
      return weighing;
    },
    currentUserId() {
      if (
        this.evaluative_agent &&
        (["duoc_coevaluacion_evaluative_agent"].includes(
          this.evaluative_agent.internal_use_id
        ) ||
          this.$equals(this.evaluative_agent.name, "Coevaluación"))
      ) {
        if (this.evaluatee_view && this.is_running_test && !this.finished_test)
          return this.coevaluator_id;
        if (
          (this.evaluatee_view && this.finished_test) ||
          this.evaluator_view
        ) {
          return this.user_id;
        }
      }
      if (
        this.evaluative_agent &&
        (["duoc_autoevaluacion_evaluative_agent"].includes(
          this.evaluative_agent.internal_use_id
        ) ||
          this.$equals(this.evaluative_agent.name, "Autoevaluación"))
      )
        return this.user_id;
      if (
        (this.evaluator_view && this.user_id) ||
        (this.evaluatee_view && this.user_id)
      )
        return this.user_id;
      return null;
    },
    currentCoevaluatorId() {
      if (
        this.evaluative_agent &&
        (["duoc_coevaluacion_evaluative_agent"].includes(
          this.evaluative_agent.internal_use_id
        ) ||
          this.$equals(this.evaluative_agent.name, "Coevaluación"))
      ) {
        if (this.evaluatee_view && this.is_running_test && !this.finished_test)
          return this.user_id;
        if (
          (this.evaluatee_view && this.finished_test) ||
          this.evaluator_view
        ) {
          return this.coevaluator_id;
        }
      }
      if (
        this.evaluative_agent &&
        (["duoc_autoevaluacion_evaluative_agent"].includes(
          this.evaluative_agent.internal_use_id
        ) ||
          this.$equals(this.evaluative_agent.name, "Autoevaluación"))
      )
        return this.user_id;
      return null;
    },
  },
  methods: {
    insertStoreOpenAiDimension() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
        visible_intelligence_options: [8],
        disabled_selected_intelligence_option: true,
      });
    },
    openModalDimensionCreate() {
      this.insertStoreOpenAiDimension();
      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          available_evaluation_criteria_micro:
            this.evaluation_criteria_micro_ids,
          extra_label: `${this.$getVisibleNames(
            "manual.dimension",
            false,
            "Dimensión"
          )}`,
          intelligence_option: 8,
          sub_option: 2,
        });
      });
      this.$bvModal.show(
        `modal-created-rubric-criteria-${this.instrument_id}-${this.container_instrument_id}`
      );
    },
    openModalDimensionEdit(rubric_criteria_id) {
      this.insertStoreOpenAiDimension();
      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          available_evaluation_criteria_micro:
            this.evaluation_criteria_micro_ids,
          extra_label: `${this.$getVisibleNames(
            "manual.dimension",
            false,
            "Dimensión"
          )}`,
          intelligence_option: 8,
          sub_option: 2,
        });
      });
      this.$bvModal.show(
        `modal-update-rubric-instance-${this.instrument_id}-${rubric_criteria_id}-${this.container_instrument_id}`
      );
    },
    hideModalDimension() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
      this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
        visible_intelligence_options: [7],
      });

      if (this.evaluation.scope == 3 && this.evaluation.scope_id != null) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.evaluation.scope_id,
            extra_context: "Para la Evaluación",
            extra_label: "",
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        });
      }
      if (
        this.egressProfileMatter &&
        this.evaluation &&
        this.evaluation.scope == 2 &&
        this.evaluation.scope_id != null
      ) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: this.egressProfileMatter.matter,
            extra_context: "Para la Evaluación",
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        });
      }
    },
    updateCheckbox(variable, value) {
      this.$store.commit("setLocalVariable", {
        component: this.$options.name,
        variable,
        value,
      });
    },
    slotStudentSelected(user_id) {
      this.coevaluator_id = user_id;
    },
    changeEvaluateeTest() {
      if (this.user_id && this.test) {
        this.$store.dispatch(names.FETCH_EVALUATEE_TESTS, {
          evaluatee_id: this.user_id,
          test_id: this.instrument_id,
        });
        this.$store.dispatch(names.FETCH_EVALUATEE_EVALUATIONS, {
          evaluatee_id: this.user_id,
          evaluation_id: this.test.evaluation,
        });
      }
    },
    orderEvaluationCriteriaMicro(evaluation_criteria_micro_id) {
      const evaluation_criteria_micro = this.evaluation_criteria_micros.find(
        (x) => x.id == evaluation_criteria_micro_id
      );
      if (evaluation_criteria_micro) {
        const study_unit = this.study_units.find(
          (x) => x.id == evaluation_criteria_micro.study_unit
        );
        if (study_unit) {
          const competence_unit = this.competence_units.find(
            (x) => x.id == study_unit.temp_competence_unit
          );
          if (competence_unit)
            return (
              competence_unit.order +
              "." +
              study_unit.order +
              "." +
              evaluation_criteria_micro.order
            );
          else return study_unit.order + "." + evaluation_criteria_micro.order;
        }
      }
      return null;
    },
    deleteRubricAchievement(rubric_achievement_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "evaluations2.rubricperformancelevel",
          false,
          "Nivel de Logro"
        )}" de la Rúbrica?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_NEW_RUBRIC_ACHIEVEMENT,
            rubric_achievement_id
          );
        }
      });
    },
    deleteRubricCriteria(rubric_criteria_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar la ${this.$getVisibleNames(
          "manual.dimension",
          false,
          "Dimensión"
        )}?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_NEW_RUBRIC_CRITERIA,
            rubric_criteria_id
          );
        }
      });
    },
    createdRubric() {
      this.$store.dispatch(names.POST_NEW_RUBRIC_INSTRUMENT, {
        title: "",
        test: this.instrument_id,
        files: [],
      });
    },
    askForDeteleRubric() {
      this.$swal({
        title: "<p>¿Está seguro de que desea eliminar la rúbrica?</p>",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_NEW_RUBRIC_INSTRUMENT, this.rubric.id)
            .then(() => {
              toast("Se eliminó la rúbrica ");
            });
        }
      });
    },
  },
  watch: {
    selectedUsers(value) {
      if (value.length == 1) {
        this.coevaluator_id = value[0];
      }
    },
    coevaluator_id() {
      if (
        this.currentUserId != null &&
        (this.evaluatee_view || this.evaluator_view)
      ) {
        this.$store.dispatch(names.FETCH_EVALUATEE_RUBRICS, {
          evaluatee_id: this.currentUserId,
          test_id: this.test.id,
        });
        this.$store
          .dispatch(names.FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS, {
            evaluatee_id: this.currentUserId,
            test_id: this.test.id,
          })
          .then((response) => {
            const eva_rub_cri_per_lev_ids = response.map((x) => x.id);
            if (eva_rub_cri_per_lev_ids.length > 0)
              this.$store.dispatch(
                names.FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS,
                {
                  evaluatee_rubric_criteria_performance_levels_ids:
                    eva_rub_cri_per_lev_ids,
                }
              );
          });
      }
    },
  },
  mounted() {
    if (
      (this.evaluatee_view || this.evaluator_view) &&
      this.evaluative_agent &&
      this.user_id &&
      (["duoc_autoevaluacion_evaluative_agent"].includes(
        this.evaluative_agent.internal_use_id
      ) ||
        this.$equals(this.evaluative_agent.name, "Autoevaluación"))
    )
      this.coevaluator_id = this.currentCoevaluatorId;
    if (
      (this.evaluatee_view || this.evaluator_view) &&
      this.evaluative_agent &&
      this.user_id &&
      (["duoc_coevaluacion_evaluative_agent"].includes(
        this.evaluative_agent.internal_use_id
      ) ||
        this.$equals(this.evaluative_agent.name, "Coevaluación"))
    ) {
      if (
        this.evaluatee_view &&
        this.is_running_test &&
        !this.finished_test &&
        this.evaluateeRubric &&
        this.evaluateeRubric.coevaluators.length == 1
      )
        this.coevaluator_id = this.evaluateeRubric.coevaluators[0];
      else if (
        this.evaluateeRubric &&
        (this.evaluator_view ||
          (this.evaluatee_view &&
            this.finished_test &&
            this.evaluateeRubric.coevaluating.length == 1))
      )
        this.coevaluator_id = this.evaluateeRubric.coevaluating[0];
    }
  },
  created() {
    if (this.filter_rubric_instance == null) {
      this.hide_dimension = this.$store.getters.getLocalVariable(
        this.$options.name,
        "hide_dimension"
      );
      this.hide_number = this.$store.getters.getLocalVariable(
        this.$options.name,
        "hide_number"
      );
      this.hide_weighing = this.$store.getters.getLocalVariable(
        this.$options.name,
        "hide_weighing"
      );
      this.hide_evaluation_criteria = this.$store.getters.getLocalVariable(
        this.$options.name,
        "hide_evaluation_criteria"
      );
    }
    // if (
    //   ((this.requests_within_the_component &&
    //     !this.evaluatee_view &&
    //     !this.evaluator_view) ||
    //     this.evaluatee_view ||
    //     this.evaluator_view) &&
    //   !this.no_requests_in_view
    // ) {
    //   this.$store.dispatch(
    //     names.FETCH_NEW_RUBRIC_INSTRUMENTS,
    //     this.instrument_id
    //   );
    // }
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css"></style>
<style scoped>
.col-popover-1 {
  z-index: 1010 !important;
}
.col-display-1 {
  min-width: 154px;
  margin-left: 2em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.col-display-1:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
  transition: all 0.3s;
}
.weighing-red {
  color: red;
}
.vertical-align-cell {
  vertical-align: middle !important;
}
.td-rubric-descriptor-cell {
  vertical-align: top;
  /* min-width: 120px; */
}
.sentence-cell >>> p {
  padding-left: 0;
  margin-left: 0;
}
.unselectable-text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container-sentence {
  display: flex;
  flex-wrap: wrap;
}
.contailer-il-rubric {
  max-height: 250px !important;
  overflow-y: auto !important;
}
.container-dimension {
  max-height: 250px !important;
  overflow-y: auto !important;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
.text-cell-sencente {
  flex: 1;
  overflow-wrap: break-word; /* Asegura que las palabras se rompan para ajustarse al contenedor */
  word-break: break-word; /* Alternativa para asegurar el ajuste de palabras en navegadores más antiguos */
}
.icon-edit {
  color: var(--kl-primary-button-color);
}
.icon-trash {
  color: red;
}
.td-rubric-descriptor-cell {
  max-width: 100px;
}
.title-rubric {
  font-size: 20pt;
}
.description-rubric {
  padding: 7px 0 7px 5px;
}
.container-buttons {
  display: flex;
  justify-content: left;
}
.container-buttons-dimension {
  display: flex;
  flex-direction: column;
}
.action-button {
  border: none;
  width: 25px;
  height: 25px;
  margin-inline: 3px;
  border-radius: 5px;
  transition: 0.3s all;
}
.action-button-delete {
  border: none;
  color: white;
  padding: 0;
}
.action-button-delete-dimension {
  color: black;
  padding: 0 3px 0 0;
  border: none;
}
table {
  width: 100%;
  font-size: var(--secondary-font-size);
}
td,
th {
  border: 1px solid black;
}
th {
  font-size: var(--secondary-font-size);
  font-weight: bold;
  background-color: var(--kl-menu-color);
  color: white;
}
tfoot {
  background-color: var(--kl-menu-color);
  color: white;
  font-weight: bold;
}
.add-button {
  color: white;
}
@media only screen and (min-width: 701px) and (max-width: 1200px) {
  .rubric-achievement-overflow {
    overflow-x: auto;
  }
  .container-sentence {
    display: block;
    text-align: left;
  }
  .table-rubric {
    overflow-x: auto;
  }
  .sentence-cell {
    max-height: 500px;
    overflow: auto;
  }
}
@media only screen and (max-width: 700px) {
  .rubric-achievement-overflow {
    overflow-x: auto;
  }
}
@media only screen and (max-width: 620px) {
  .rubric-achievement-overflow {
    overflow-x: auto;
  }
}
@media print {
  .sentence-cell {
    overflow: hidden !important;
  }
  .contailer-il-rubric,
  .container-dimension {
    overflow: hidden !important;
    max-height: 100% !important;
  }
}
</style>

